import { FaCode, FaFire, FaTwitter } from 'react-icons/fa';
import { MdDevices } from 'react-icons/md';
import { SiGithub, SiLinkedin, SiYoutube } from 'react-icons/si';

export const techStacks = [
  { name: 'React', image: 'react' },
  { name: 'Node.js - Express', image: 'express' },
  { name: 'MongoDB', image: 'mongodb' },
  { name: 'Firebase', image: 'firebase' },
  { name: 'Docker', image: 'docker' },
  { name: 'AWS', image: 'amazonwebservices' },
  { name: 'Heroku', image: 'heroku' },
  { name: 'Netlify', image: 'netlify' },
  { name: 'Vercel', image: 'vercel' },
  { name: 'GraphQL', image: 'graphql' },
  { name: 'TypeScript', image: 'typescript' },
  { name: 'Next.js', image: 'nextdotjs' },
  { name: 'Gatsby', image: 'gatsby' },
  { name: 'Strapi', image: 'strapi' },
  { name: 'JAMstack', image: 'jamstack' },
  { name: 'Tailwind CSS', image: 'tailwindcss' },
  { name: 'Chakra UI', image: 'chakraui' },
  { name: 'Material-UI', image: 'mui' },
  { name: 'Ant Design', image: 'antdesign' },
  { name: 'Bootstrap', image: 'bootstrap' },
  { name: 'Sass', image: 'sass' },
  { name: 'Styled Components', image: 'styledcomponents' },
  { name: 'Redux', image: 'redux' },
  { name: 'MobX', image: 'mobx' },
  { name: 'Jest', image: 'jest' },
  { name: 'Cypress', image: 'cypress' },
  { name: 'Jenkins', image: 'jenkins' },
  { name: 'Travis CI', image: 'travisci' },
  { name: 'Git', image: 'git' },
  { name: 'GitHub', image: 'github' },
  { name: 'GitLab', image: 'gitlab' },
  { name: 'Bitbucket', image: 'bitbucket' },
];

export const features = [
  {
    title: 'Full Stack Development',
    description:
      'With comprehensive knowledge of both front-end and back-end technologies, I craft complete web solutions that power successful websites.',
    icon: FaFire,
    link: '/projects',
    btnText: 'View Projects',
  },
  {
    title: 'Responsive Design',
    description:
      'Beautiful, responsive design ensuring a flawless user experience on desktops, tablets, and smartphones alike.',
    icon: MdDevices,
    link: '/websites',
    btnText: 'View Websites',
  },
  {
    title: 'Clean Code',
    description:
      'Writing clean, maintainable code that aligns with industry standards and provides a solid foundation for future enhancements.',
    icon: FaCode,
    link: '/projects',
    btnText: 'View Code Samples',
  },
];

export const socialLinks = [
  {
    name: 'GitHub',
    url: 'https://github.com/suman-jaiswal',
    icon: SiGithub,
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/skj1729/',
    icon: SiLinkedin,
  },
  {
    name: 'X',
    url: 'https://x.com/sumanj631',
    icon: FaTwitter,
  },
  {
    name: 'Youtube',
    url: 'https://youtube.com/@codefit-2024?si=xs0-MpOv9ZwQxlt_',
    icon: SiYoutube,
  },
];

export const testimonials = [
  {
    name: 'John Doe',
    title: 'CEO, XYZ Company',
    message: `Suman is a talented developer who is always willing to go the extra mile to ensure that the project is completed successfully. He is a great team player and a pleasure to work with.`,
  },
  {
    name: 'Alice Doe',
    title: 'Founder, PQR Company',
    message: `Suman is a dedicated developer who is passionate about his work. He is always eager to learn new technologies and apply them to create innovative solutions. I look forward to working with him again.`,
  },
];
