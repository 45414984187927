import { ArrowForwardIcon, Icon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ChakraProvider,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Spacer,
  Tag,
  TagLabel,
  Text,
  theme,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FaQuoteRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import * as simpleIcons from 'simple-icons';
import { Navbar } from './components/Navbar';
import { features, socialLinks, techStacks, testimonials } from './constants';

console.log(simpleIcons);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box w={'100%'} position={'fixed'} top={0} zIndex={1}>
        <Navbar />
      </Box>

      {/* Hero Section  */}
      <Box h={'82vh'}>
        <SimpleGrid h={'100%'} columns={[1, 2]}>
          <VStack my={'auto'} align={'end'}>
            <br />
            <br />
            <Box
              spacing={4}
              w={['95%', '90%', '67%']}
              textAlign={['center', 'start']}
              mx={['auto', 'unset']}
            >
              <Box>
                <Tag size={'md'} borderRadius="full" py={2} px={4}>
                  <TagLabel>
                    Explore My Work - <Link href="/blogs">Read My Blog</Link>
                  </TagLabel>
                </Tag>
              </Box>
              <br />
              <Heading
                size={'3xl'}
                fontWeight={'bold'}
                textAlign={['center', 'start']}
              >
                Welcome to CodeFit!
              </Heading>
              <br />
              <Text fontSize={'xl'}>
                Here I share my journey in web and app development through my
                portfolio and insightful tutorials. I am a Full Stack Developer
                with a passion for creating beautiful and functional software
                solutions.
              </Text>
              <br /> <br />
              <HStack spacing={4} justifyContent={['center', 'start']}>
                <Button
                  href="/tutorials"
                  rightIcon={<ArrowForwardIcon />}
                  colorScheme="blue"
                >
                  Start Learning
                </Button>
                <Button href="/contact">Let's Connect</Button>
              </HStack>
            </Box>
          </VStack>
          <Image
            hideBelow={'md'}
            style={{ clipPath: 'polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%' }}
            src="hero.jpg"
            h={'100%'}
            fit={'cover'}
          />
        </SimpleGrid>
      </Box>

      {/* Tech Stacks Section */}
      <Box bg={'gray.100'} py={10}>
        <Heading textAlign={'center'}>Tech Weapons</Heading>
        <br />
        <Text fontSize={'lg'} align={'center'}>
          These are a few of the modern and great performance tech stacks I use
          to get the crafting done.
        </Text>
        <br />
        <br />
        <Box w={'100%'} overflowX={'auto'}>
          <Flex
            spacing={10}
            wrap={'wrap'}
            w={'1300px'}
            mx={'auto'}
            justifyContent={['start', 'center']}
          >
            {techStacks.map(tech => {
              const icon = Object.values(simpleIcons).find(
                icon => icon.slug.toLowerCase() === tech.image.toLowerCase()
              );
              const brandColor = `#${icon.hex}`;
              const svg = icon.svg.replace('<svg', `<svg fill="${brandColor}"`);
              return (
                <HStack key={tech.name} rounded={'md'} mx={4} my={3}>
                  <Box
                    dangerouslySetInnerHTML={{ __html: svg }}
                    color={brandColor}
                    p={2}
                    rounded={10}
                    w={[10, 12]}
                  />
                  <Text>{tech.name}</Text>
                </HStack>
              );
            })}
          </Flex>
        </Box>
        <br />
      </Box>

      {/* Features */}
      <Box py={10}>
        <Heading textAlign={'center'}>Why CodeFit?</Heading>
        <br />
        <Text fontSize={'lg'} align={'center'} px={2}>
          At DevCraft, I merge creativity with coding expertise to deliver
          exceptional digital experiences that stand out in the bustling web
          landscape.
        </Text>
        <br />
        <br />
        <Container maxW={'container.xl'}>
          <SimpleGrid columns={[1, 3]} spacing={10} mx={'auto'}>
            {features.map(feature => (
              <Card
                key={feature.title}
                rounded={10}
                background={
                  'linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%);'
                }
                color={'white'}
                p={3}
              >
                <CardHeader>
                  <Icon as={feature.icon} boxSize={10} color={'white'} />
                  <br />
                  <br />
                  <Heading size="md">{feature.title}</Heading>
                </CardHeader>
                <CardBody py={3}>
                  <Text>{feature.description}</Text>
                </CardBody>
                <CardFooter>
                  <Button as={NavLink} to={feature.link}>
                    {feature.btnText}
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </SimpleGrid>
        </Container>
      </Box>

      {/* Testimonials */}
      <Box py={10}>
        <Heading textAlign={'center'}>Testimonials</Heading>
        <br />
        <Text fontSize={'lg'} align={'center'} px={2}>
          Here's what my clients and colleagues have to say about my work.
        </Text>
        <br />
        <br />
        <Container maxW={'container.xl'}>
          <SimpleGrid columns={[1, 2]} spacing={10} mx={'auto'}>
            {testimonials.map(testimonial => (
              <Card rounded={10} bg={'gray.100'} p={3}>
                <CardHeader>
                  <FaQuoteRight />
                  <br />
                  <Heading size="md">{testimonial.name}</Heading>
                  <p>{testimonial.title}</p>
                </CardHeader>
                <CardBody pt={2}>
                  <Text>{testimonial.message}</Text>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </Container>
      </Box>

      {/* Footer Section */}
      <Box bg={'gray.800'} color={'white'} py={10}>
        <Container maxW={'container.xl'}>
          <Flex
            w={'100%'}
            mx={'auto'}
            gap={2}
            flexDirection={['column', 'row']}
            justifyContent={'center'}
            align={'center'}
          >
            <Text textAlign={'center'} fontSize={'md'}>
              &copy; 2024 CodeFit. All rights reserved.
            </Text>
            <Spacer />
            <HStack spacing={5}>
              {/* Social links */}
              {socialLinks.map(social => (
                <Link key={social.name} href={social.url}>
                  <Icon as={social.icon} boxSize={5} color={'white'} />
                </Link>
              ))}
            </HStack>
          </Flex>
        </Container>
      </Box>
    </ChakraProvider>
  );
}

export default App;
