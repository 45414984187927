import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Link,
  Spacer,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { DrawerComponent } from './DrawerComponent';

export const Navbar = () => {
  return (
    <Container maxW={'container.xl'} p={0}>
      <Flex
        bg={'white'}
        boxShadow={'lg'}
        p={5}
        my={[0, 3]}
        rounded={'md'}
        alignItems={'center'}
      >
        <Image src="logo.png" h={['25px', '30px', '35px']} />
        <Spacer />
        <Box hideBelow={'lg'}>
          <HStack spacing={10}>
            <Link as={NavLink} to="/tutorials">
              Tutorials
            </Link>
            <Link as={NavLink} to="/projects">
              Projects
            </Link>
            <Link as={NavLink} to="/blogs">
              Blogs
            </Link>
            <Link as={NavLink} to="/contact">
              Contact
            </Link>
          </HStack>
        </Box>
        <Box hideFrom={'lg'}>
          <DrawerComponent />
        </Box>
        <Spacer hideBelow={'lg'} />
        <Box hideBelow={'lg'}>
          <HStack spacing={5}>
            <Button>Register</Button>
            <Button colorScheme="blue">Login</Button>
          </HStack>
        </Box>
      </Flex>
    </Container>
  );
};
