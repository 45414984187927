import { Container, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { GiSandsOfTime } from 'react-icons/gi';
import { Link } from 'react-router-dom';

export const ComingSoon = () => {
  return (
    <Container mx={'auto'} maxW={'container.md'}>
      <Link to={'/'}>
        <Image src="logo.png" alt="CodeFit" mx={'auto'} mt={10} />
      </Link>
      <VStack py={10} justifyContent={'center'} h={'75vh'}>
        <GiSandsOfTime size={100} mx={'auto'} />
        <br />
        <Heading textAlign={'center'}>Coming Soon</Heading>
        <Text fontSize={'lg'} align={'center'} px={2}>
          This page is under construction. Please check back later.
        </Text>
      </VStack>
    </Container>
  );
};
